<template>
  <order-list
    :extra-filters="{
        customer_type: 4
      }"
    :resource-path="`orders/${currentStatus}`"
  >
    <template v-slot:actions>
      <v-btn :outlined="currentStatus === 'unprocessed'"
             :to="{name:'orders-public', params:{'status' : 'unprocessed'}}" class="mx-1 mb-2 primary-button" color="primary">
        De procesat
      </v-btn>
      <v-btn :outlined="currentStatus === 'unfinished'"
             :to="{name:'orders-public', params:{'status' : 'unfinished'}}" class="mx-1 mb-2" color="error">
        Nefinalizate
      </v-btn>
      <v-btn :outlined="currentStatus === 'finished'"
             :to="{name:'orders-public', params:{'status' : 'finished'}}" class="mx-1 mb-2">
        Finalizate si platite
      </v-btn>
      <v-spacer/>
      <add-new-order-dialog v-if="$store.getters['auth/currentUser'].is_admin"/>
    </template>
  </order-list>
</template>

<script>
import OrderList from '@/views/Orders/OrderList'
import AddNewOrderDialog from '@/views/Orders/CreateOrder'

export default {
  name: 'OrderListPublic',
  components: {
    AddNewOrderDialog,
    OrderList
  },
  computed: {
    currentStatus () {
      return this.$route.params.status
    }
  }
}
</script>
